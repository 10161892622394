<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title-with-search
          :title="$t('title.server')"
          :search="true"
          class="stack-info__title"
        >
          {{ $t('title.server') }}
          <template #search>
            <tags-filter
              :search="search"
              search-class="max"
              @search-server="searchNetwork($event)"
              class="search"
            ></tags-filter
          ></template>
        </page-title-with-search>
      </div>
      <base-empty v-if="list.length === 0" title="Новая приватная сеть" class="cloud-info__empty">
        <template #link>
          <base-button>
            {{ $t('newServer') }}
          </base-button>
        </template>
      </base-empty>
      <div v-else>
        <tariffs-table-network
          :search="search"
          :search-filter="searchFilter"
          :table-head="tableHead"
        />
      </div>
    </page-block>
  </div>
</template>

<script>
import TariffsTableNetwork from '../../Main/components/TariffsTableNetwork';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newPrivateNetwork from '@/layouts/Stack/mixins/newPrivateNetwork';
import PageBlock from '@/layouts/Stack/pages/Information/components/PageBlock.vue';
import PageTitleWithSearch from '@/components/PageTitle/PageTitleWithSearch.vue';
import TagsFilter from '@/layouts/Stack/components/TagsFilter.vue';
export default {
  name: 'ViewNetwork',
  components: {
    TagsFilter,
    PageTitleWithSearch,
    TariffsTableNetwork,
    BaseEmpty,
    PageBlock,
  },
  mixins: [storeMixin, newPrivateNetwork],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      search: '',
      searchFilter: '',
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            minWidth: '480px',
            maxWidth: '500px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'subnet',
          label: this.$t('tableHead.subnet'),
          style: {
            minWidth: '130px',
            maxWidth: '150px',
          },
        },
        {
          key: 'conditions',
          label: this.$t('tableHead.conditions'),
          style: {
            minWidth: '50px',
            maxWidth: '84px',
            textAlign: 'center',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            minWidth: '50px',
            maxWidth: '80px',
            textAlign: 'center',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            minWidth: '44px',
            maxWidth: '48px',
            textAlign: 'center',
          },
        },
      ],
      isRequest: true,
      diskId: '',
    };
  },
  computed: {
    isPrivateNetwork() {
      if (this.$store.state.moduleStack.quotasNetwork?.network) {
        return (
          this.$store.state.moduleStack.quotasNetwork.network.limit -
          this.$store.state.moduleStack.quotasNetwork.network.used
        );
      } else return 0;
    },
  },
  watch: {
    isPrivateNetwork: {
      handler: function (event) {
        this.$emit('change-type-create-button', {
          quota: this.isPrivateNetwork === 0,
          type: 'network',
        });
      },
      immediate: true,
    },
    '$route.query.value': {
      handler: function (val) {
        this.searchFilter = val;
        this.search = val;
      },
      immediate: true,
    },
    searchFilter: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    this.search = '';
    this.setFilter(this.$route.query.value);
  },
  methods: {
    searchNetwork(event) {
      this.searchFilter = event;
    },
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    setFilter(val) {
      this.searchFilter = val;
      this.search = val;
      this.$router
        .push({
          name: 'containerNetwork',
          query: { filter_by: 'name', value: val },
        })
        .catch(() => {});
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Приватная сеть",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "newServer": "Новая сеть",
    "quotaNetwork": "Вы достигли квоты по количеству приватных сетей, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.search {
  min-width: 10rem;
}
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        //flex: 1 1 auto;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
          &-quota{
            margin: 20px 0 0 0;
            position: relative;
            padding: 1em 1.5rem;
            border-radius: 6px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            background: #0f68e4;
            color: #fff;
            border: 1px solid transparent;
            opacity: 0.2;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }

          }

        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
