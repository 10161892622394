import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import ShowMultipartUpload from '@/layouts/Stack/components/ShowMultipartUpload.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';

export default {
  mixins: [showErrorModal, modals, showErrorOpenStackModal],
  data() {
    return {
      data: {},
      nameObject: '',
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    showMultipartUpload: function (storage, multipartUploads) {
      // console.log(storage, multipartUploads);
      this.data.bucket = storage;
      // this.data.key = folder;
      const that = this;
      // this.nameObject = instance;
      return new Promise(() => {
        const selfName = 'ShowMultipartUpload';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ShowMultipartUpload,
          closeOnBackdrop: false,
          props: { storage, multipartUploads },
          on: {
            change: data => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
              // console.log('change', data);
              this.data.policy = data.category;
              this.data.allOblects = data.allKey;
            },
            notready: () => {
              // Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // confirm: {
            //   props: { title: this.$t('sure.delete'), color: 'error' },
            //   on: {
            //     click: async () => {
            //       Vue.set(this.modal.footer.confirm.props, 'loading', true);
            //       await this.send().then(data => {
            //         Vue.set(this.modal.footer.confirm.props, 'loading', false);
            //       });
            //     },
            //   },
            // },
            centered: true,
            confirm: {
              props: { title: this.$t('sure.close'), color: 'primary' },
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async send() {
      const params = {
        name: this.data.bucket,
        policy: this.data.policy,
        obj: this.data.key,
      };
      // console.log(params);
      const bucket = {
        storage: this.data.bucket,
        delimiter: '/',
      };
      if (!this.data.allOblects)
        await this.$store
          .dispatch('moduleStack/setACLObject', params)
          .then(async () => {
            await this.$store.dispatch('moduleStack/fetchCurrentStorages', bucket);
          })
          .catch(e => this.showErrorS3(e));
      else {
        // console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww');
        await this.$store
          .dispatch('moduleStack/setACLObject', params)
          .then(async data => {
            // console.log(data);
            // this.tariff.objects.forEach(x => {
            this.tariff.objectForAcl.forEach(x => {
              // console.log(x);
              const payload = {
                name: this.data.bucket,
                obj: x.key,
                policy: this.data.policy,
              };
              this.$store
                .dispatch('moduleStack/setACLObject', payload)
                .catch(e => console.error(e));
            });
            await this.$store.dispatch('moduleStack/fetchCurrentStorages', bucket);
          })
          .catch(e => this.showErrorS3(e));
      }
    },
  },
};
