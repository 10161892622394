<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariff-table-item__server">
        <router-link
          :to="{ name: 'Private', params: { privateid: item.id } }"
          class="tariffs-table-item__link medium-text"
        >
          <div class="hover">
            <text-highlight :queries="filtredSearch"> {{ item.name }}</text-highlight>
          </div>
          <!--        {{ item.name }}-->
        </router-link>

        <div class="tariffs-table-item__specs note-text">
          {{ item.description }}
        </div>
        <div class="tariffs-table-item__specs note-text">
          {{ specs }}
        </div>
      </div>
    </template>
    <template #subnet>
      <div v-for="i in item.subnets" :key="i" class="tariffs-table-item__sub">
        {{ viewSubnets(i) }}
      </div>
    </template>
    <template #shared>
      <div class="tariffs-table-item__ip">
        {{ item.shared }}
      </div>
    </template>
    <template v-if="!type" #conditions>
      <tumbler :value="item.admin_state_up" class="theme-switcher__tumbler" @change="setState" />
      <main-label
        :color="item.admin_state_up === true ? 'success' : 'error'"
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{ item.admin_state_up === true ? 'Активна' : 'Неактивна' }}
      </main-label>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template v-if="!type" #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <template #popover>
          <private-network-context-menu :tariff="item" class="tariff-table-item__context-menu" />
        </template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import PrivateNetworkContextMenu from './PrivateNetworkContextMenu';
import popoverFix from '@/mixins/popoverFix';
// import { format } from 'date-fns';
import Tumbler from '@/components/BaseTumbler/Tumbler.vue';
export default {
  name: 'TariffsTableNetworkRow',
  components: {
    BaseTableRow,
    MainLabel,
    Tumbler,
    PrivateNetworkContextMenu,
  },
  mixins: [popoverFix],
  props: {
    filtredSearch: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
    specs() {
      return `${this.$t('specs.id')}${this.item.id}`;
    },
  },
  watch: {
    viewSubnets: function (id) {
      // console.log(id);
      return `${this.subnets.find(i => i.id === id).name} ${
        this.subnets.find(i => i.id === id).cidr
      }`;
    },
  },
  methods: {
    printParam(param, payload) {
      return param.toString(payload);
    },
    viewSubnets(id) {
      return this.subnets.find(i => i.id === id) ? this.subnets.find(i => i.id === id).cidr : '';
    },
    setState(value) {
      const payload = {
        network: {
          admin_state_up: value,
        },
        id: this.item.id,
        item: 'admin_state_up',
      };
      this.$store.dispatch('moduleStack/updateNetwork', payload);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "specs": {
      "id": "ID:",
      "ip": "IP:",
      "disc": "Disc"
    },
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.theme-switcher__tumbler{
  display: flex
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__specs {
    flex: 1 1 auto;
    margin-top: 0.12rem;
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    width: 100%
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
</style>
