<template>
  <base-loader v-if="loader" />
  <div v-else class="multipart">
    <div class="multipart-title standart-text">
      {{ $t('title') }}
    </div>
    <div class="multipart-item">
      <div class="multipart-item__field">
        <label class="standart-text">{{ $t('name') }}</label>
        <label class="standart-text">{{ $t('date') }}</label>
      </div>
    </div>
    <div class="multipart-item">
      <div v-for="upload of multipartUploads" :key="upload.Key" class="multipart-item__value">
        <label class="standart-text">{{ upload.Key }}</label>
        <label class="standart-text">{{ format(upload.Initiated, 'dd.MM.yyyy, HH:mm') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import { format } from 'date-fns';

export default {
  name: 'ShowMultipartUpload',
  components: {},
  directives: {
    tooltip: Tooltip,
  },
  props: {
    storage: {
      type: String,
      default: '',
    },
    multipartUploads: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mode: 'edit',
      selectedCategory: null,
      config: {
        values: {
          on: true,
          off: false,
        },
        value: 'off',
        trueValue: 'on',
        falseValue: 'off',
      },
      formData: {},
      loader: false,
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    tariff() {
      return this.$store.state.moduleStack.storages.find(x => x.name === this.storage);
    },
  },
  watch: {
    loader(event) {
      if (event) this.$emit('notready');
      else
        this.$emit('change', {
          category: this.selectedCategory.key,
          allKey: this.config.value === 'on',
        });
    },
    config: {
      handler: function (event) {
        // console.log(event);
      },
      deep: true,
    },
  },
  beforeDestroy() {},
  mounted() {
    this.$emit('notready');
  },
  methods: {
    format,
    onChange(name, event) {
      this.formData[name] = event;
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "type": "Настройки конфиденциальности",
    "name": "Имя загружаемого объекта:",
    "date": "Дата начала загрузки:",
    "all": "Применить к содержимому папки",
    "title": "В контейнере присутствуют незавершённые составные загрузки:",
    "descr": "Применить ко всем вложенным объектам папки",
    "private": "Приватный",
    "public": "Публичный"

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.multipart{
  width: 100%
  display: flex
  flex-direction: column;
  //gap: 1rem

  &-title {
    margin-top: 0.5 rem;
    margin-bottom: 1 rem
  }
  &-item {
    margin: 0.5rem 0

    &__value {
      display: flex;
      align-items: center;
      margin: 1rem 0
      justify-content: space-between;
    }

    &__field {
      display: flex;
      //gap: 15rem;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      &-block {
        margin: 0.5rem 0

        &__name {
          display: flex;
          gap: 1rem
        }
      }
    }
  }
}
.addons {
  &__input{
    height: 36px;
    width: 100%
  }
  &__label{
    margin-top: -1rem;

  }
  &__value{
    min-width: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      width: 130px;
      margin-top: 1rem

      & + & {
        margin-left: 2rem
      }
    }

    & + & {
      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 12rem;
      margin-right: 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
