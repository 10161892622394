import Vue from 'vue';
import modals from '@/mixins/modals';
import NewStorage from '@/layouts/Stack/pages/Order/NewStorage.vue';
import showErrorModal from '@/mixins/showErrorModal';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  mixins: [modals, showErrorOpenStackModal],
  data() {
    return {
      container: '',
      policy: {},
    };
  },
  methods: {
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.storage.id,
      });
    },
    newStorage(id) {
      this.id = id;
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewStorage',
          size: 'medium',
          component: NewStorage,
          closeOnBackdrop: false,
          props: { id: id },
          on: {
            change: data => {
              console.log(data);
              this.container = data.name;
              if (data.policy) this.policy = data.policy;
              else this.policy = {};
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  const params = {
                    name: this.container,
                    policy: this.policy,
                  };
                  // const payload = {
                  //   name: this.container,
                  // };
                  this.createContainer(this.container)
                    .then(async () => {
                      if (this.policy)
                        await this.$store.dispatch('moduleStack/putBucketPolicy', params);
                      that.$modals.close();
                      this.showResModal(`Контейнер <b>${this.container}</b> успешно создан.`);
                      await this.$store.dispatch('moduleStack/fetchProjectStorages', {
                        delimiter: '/',
                        id: this.id,
                      });
                      await this.getS3BucketList();
                    })
                    .catch(e => {
                      Vue.set(this.modal.footer.confirm.props, 'loading', false);
                      let error;
                      if (e?.message === 'BucketAlreadyExists') {
                        error =
                          'Имя контейнера уже существует на другом проекте. Пожалуйста, выберите другое имя.';
                      } else error = e.message;

                      this.showErrorS3(error);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async createContainer(name) {
      const params = {
        name: name,
        // type: type,
      };
      await this.$store.dispatch('moduleStack/createS3Bucket', params);
    },
  },
};
