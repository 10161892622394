<template>
  <div class="page-title">
    <div v-if="title || $slots.title" class="page-title__head">
      <slot name="title">
        <h1 class="page-title__title">{{ title }}</h1>
      </slot>
    </div>
    <div v-if="$slots.search" class="page-title__search">
      <slot name="search" />
    </div>
    <!--    <h1 class="page-title">-->
    <!--      <slot>{{ props.text }}</slot>-->
    <!--    </h1>-->
  </div>
</template>

<script>
export default {
  name: 'PageTitleWithSearch',
  props: {
    title: {
      type: String,
      default: null,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.page-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;


  +breakpoint(sm-and-up) {
    margin-top: 1.5rem;
  }

  &__title {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: var(--title);
    transition: color 0.5s ease;

    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }
  }

  &__title {
    flex: 1 1 auto;

    :not(:only-child) {
      margin-right: 1rem;
    }
  }

  &__search {
    //flex: 0 0 auto;
    display: flex;
    margin-top: 1rem;
    width: 75%

    +breakpoint(sm-and-up) {
      margin-top: unset;
    }
  }
}
</style>
